<template>
  <div>
    <b-modal
      ref="qr-code-modal"
      :titleHtml="modalTitle"
      @hide="hideModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <b-row class="qr-content">
        <b-col class="qr-content__left" sm="5">
          <div class="qr-content__code">
            <b-img
              rounded
              v-bind:src="'data:image/png;base64, ' + qrCode.image"
              v-bind="imgProps"
              alt="QRCode Img"
            />
            <div class="qr-content__amount">
              <span>Tổng tiền: </span>
              <b>{{ paymentAmount }}</b>
            </div>
          </div>
        </b-col>
        <b-col class="qr-content__right" sm="7">
          <div class="qr-content__desc">
            <h4 class="mb-6">
              Quét mã QR để thanh toán
              {{
                payment.paymentType !== PAYMENT_TYPE.TRANSFER
                  ? `${payment.paymentName}`
                  : ''
              }}
            </h4>
            <div class="step-description" style="flex-wrap: wrap;">
              <span class="step-number">1</span> Mở app ngân hàng
              {{
                payment.paymentType !== PAYMENT_TYPE.TRANSFER
                  ? `hoặc ${payment.paymentName}`
                  : ''
              }}
              và chọn tính năng
              <v-icon class="qr-code-icon">mdi-qrcode-scan</v-icon> quét mã QR.
            </div>
            <div class="step-description" v-if="qrCode.code">
              <span class="step-number">2</span> Đảm bảo nội dung chuyển khoản
              là <span class="qr-code-verify">{{ qrCode.code }}</span
              >.
            </div>
            <div class="step-description">
              <span class="step-number">{{ qrCode.code ? 3 : 2 }}</span> Thực
              hiện thanh toán.
            </div>
          </div>
          <div class="qr-content__time">
            <p>Giao dịch kết thúc sau</p>
            <div class="countdown-time__style">
              <span class="min">{{ minutes }}</span
              ><span class="separate-time__style">:</span>
              <span class="second">{{ seconds }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="supported-bank">
        <div class="supported-bank__title">Ngân hàng hỗ trợ thanh toán</div>
        <div class="supported-bank__list">
          <template v-for="(bank, index) in bankList">
            <b-img
              :src="bank.url"
              :key="index"
              style="width: 80px; height:42px;object-fit: contain"
            />
          </template>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import { makeToastFaile, convertPrice } from '@/utils/common';
import { PAYMENT_TYPE } from '@/utils/enum';
export default {
  props: [
    'billNumber',
    'transferAmount',
    'onGenerateQR',
    'storeId',
    'afterGenerateQR',
    'type',
    'payment',
    'billData',
  ],
  computed: {
    paymentAmount() {
      return this.convertPrice(this.payment.paymentAmount, 'vn-VN', {
        style: 'currency',
        currency: 'VND',
      });
    },
  },
  data() {
    return {
      PAYMENT_TYPE: PAYMENT_TYPE,
      convertPrice,
      imgProps: {
        center: true,
        fluidGrow: true,
        blankColor: '#bbb',
        width: 230,
        height: 230,
      },
      modalTitle: 'Thanh toán bằng QR code',
      countdown: 300,
      stopCountdown: 0,
      image: null,
      qrCode: {
        code: '',
        image: '',
      },
      bankList: [
        {
          name: 'abb',
          url: 'media/logos/bank-abb.png',
        },
        {
          name: 'acb',
          url: 'media/logos/bank-acb.png',
        },
        {
          name: 'bab',
          url: 'media/logos/bank-bab.png',
        },
        {
          name: 'bidv',
          url: 'media/logos/bank-bidv.png',
        },
        {
          name: 'bvb',
          url: 'media/logos/bank-bvb.png',
        },
        {
          name: 'cake',
          url: 'media/logos/bank-cake.png',
        },
        {
          name: 'cimb',
          url: 'media/logos/bank-cimb.png',
        },
        {
          name: 'coop',
          url: 'media/logos/bank-coopbank.png',
        },
        {
          name: 'eib',
          url: 'media/logos/bank-eib.png',
        },
        {
          name: 'hdb',
          url: 'media/logos/bank-hdb.png',
        },
        {
          name: 'icb',
          url: 'media/logos/bank-icb.png',
        },
        {
          name: 'ivb',
          url: 'media/logos/bank-ivb.png',
        },
        {
          name: 'kbank',
          url: 'media/logos/bank-kbank.png',
        },
        {
          name: 'klb',
          url: 'media/logos/bank-klb.png',
        },
        {
          name: 'lpb',
          url: 'media/logos/bank-lpb.png',
        },
        {
          name: 'mb',
          url: 'media/logos/bank-mb.png',
        },
        {
          name: 'msb',
          url: 'media/logos/bank-msb.png',
        },
        {
          name: 'nab',
          url: 'media/logos/bank-nab.png',
        },
        {
          name: 'ncb',
          url: 'media/logos/bank-ncb.png',
        },
        {
          name: 'mbbank',
          url: 'media/logos/bank-mbbank.png',
        },
        {
          name: 'ocb',
          url: 'media/logos/bank-ocb.png',
        },
        {
          name: 'ocean',
          url: 'media/logos/bank-oceanbank.png',
        },
        {
          name: 'pgb',
          url: 'media/logos/bank-pgb.png',
        },
        {
          name: 'pvcb',
          url: 'media/logos/bank-pvcb.png',
        },
        {
          name: 'scb',
          url: 'media/logos/bank-scb.png',
        },
        {
          name: 'seab',
          url: 'media/logos/bank-seab.png',
        },
        {
          name: 'sgicb',
          url: 'media/logos/bank-sgicb.png',
        },
        {
          name: 'shb',
          url: 'media/logos/bank-shb.png',
        },
        {
          name: 'shbvn',
          url: 'media/logos/bank-shbvn.png',
        },
        {
          name: 'stb',
          url: 'media/logos/bank-stb.png',
        },
        {
          name: 'tcb',
          url: 'media/logos/bank-tcb.png',
        },
        {
          name: 'timo',
          url: 'media/logos/bank-timo.png',
        },
        {
          name: 'tpb',
          url: 'media/logos/bank-tpb.png',
        },
        {
          name: 'ubank',
          url: 'media/logos/bank-ubank.png',
        },
        {
          name: 'vab',
          url: 'media/logos/bank-vab.png',
        },
        {
          name: 'vba',
          url: 'media/logos/bank-vba.png',
        },
        {
          name: 'vcb',
          url: 'media/logos/bank-vcb.png',
        },
        {
          name: 'vccb',
          url: 'media/logos/bank-vccb.png',
        },
        {
          name: 'vib',
          url: 'media/logos/bank-vib.png',
        },
        {
          name: 'vietbank',
          url: 'media/logos/bank-vietbank.png',
        },
        {
          name: 'vpb',
          url: 'media/logos/bank-vpb.png',
        },
        {
          name: 'wvn',
          url: 'media/logos/bank-wvn.png',
        },
      ],
      minutes: null,
      seconds: null,
      timeTrigger: null,
      isExpired: false,
    };
  },
  watch: {
    isExpired: {
      handler: function(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.hideModal();
          Swal.fire('Không thành công', 'Hết thời gian thanh toán!', 'error');
        }
      },
    },
  },
  methods: {
    startTimer(duration) {
      let timer = duration,
        minutes,
        seconds;
      this.timeTrigger = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        this.minutes = minutes < 10 ? '0' + minutes : minutes;
        this.seconds = seconds < 10 ? '0' + seconds : seconds;
        if (--timer < 0) {
          this.isExpired = true;
          timer = duration;
        }
      }, 1000);
    },
    generateQRCode: async function() {
      this.$emit('onGenerateQR');
      if (
        this.payment &&
        this.payment.paymentType === PAYMENT_TYPE.INSTALLMENT &&
        this.payment.paymentRefCode.toUpperCase() === 'MOMO ONLINE'
      ) {
        const data = {
          billNumber: this.billNumber,
          amount: this.payment['paymentAmount'],
        };

        await ApiService.post('/qr-code/momo', data)
          .then((response) => {
            const { data, status } = response.data;
            if (status === 1) {
              this.qrCode = data;
              this.showModal();
              this.$emit('afterGenerateQR');
            }
          })
          .catch(({ response }) => {
            const { status, message } = response.data;
            if (status === 0) {
              this.$emit('afterGenerateQR');
              makeToastFaile(message);
            }
          });
        return;
      }
      const data = {
        referenceNumber: this.billNumber,
        type: this.type,
        paymentInfo: this.payment,
      };
      await ApiService.post('/qr-code', data)
        .then((response) => {
          const { data, status } = response.data;
          if (status === 1) {
            this.qrCode = data;
            this.showModal();
            this.$emit('afterGenerateQR');
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            this.$emit('afterGenerateQR');
            makeToastFaile(message);
          }
        });
    },
    showModal: async function() {
      const duration = 60 * 10;
      this.startTimer(duration);

      this.$refs['qr-code-modal'].show();
    },
    hideModal: async function() {
      window.clearInterval(this.timeTrigger);
      this.$refs['qr-code-modal'].hide();
    },
    submit() {
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.qr-content {
  padding: 20px 24px;
}
.qr-content__left {
  background: rgb(245, 245, 250);
  border-radius: 16px;
  padding: 16px;
  margin-right: 40px;
  height: 300px;
  max-width: 38.67%;
}
.qr-content__code {
  width: 240px;
  height: 240px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(40 40 43 / 16%) 0px 2px 8px;
  border-radius: 8px;
  padding: 5px;
  margin: auto;
}
.qr-content__amount {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
}
.qr-content__right {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
}

.step-description {
  color: rgb(128, 128, 137);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
.step-number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(26, 148, 255);
  color: rgb(255, 255, 255);
  margin-right: 8px;
  font-weight: 500;
  flex-shrink: 0;
  text-align: center;
}
.qr-content__time {
  background: rgb(255, 245, 236);
  border-radius: 4px;
  padding: 13px 0px;
  display: block;
  text-align: center;
  margin-top: auto;
}
.supported-bank {
  padding: 24px;
  border-top: 1px solid rgb(235, 235, 240);
}
.supported-bank__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgb(56, 56, 61);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.supported-bank__list {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  max-height: 123px;
  overflow-y: scroll;
  gap: 16px 25px;
}

.countdown-time__style {
  text-align: center;
}

.min {
  background: rgb(253, 130, 10);
  color: rgb(255, 255, 255);
  padding: 4px 7px;
  display: inline-block;
  border-radius: 8px;
  margin: 0px 10px;
  font-size: 15px;
}

.second {
  background: rgb(253, 130, 10);
  color: rgb(255, 255, 255);
  padding: 4px 7px;
  display: inline-block;
  border-radius: 8px;
  margin: 0px 10px;
  font-size: 15px;
}
.separate-time__style {
  font-weight: 600;
  font-size: 16px;
}
.qr-code-icon {
  margin: 0 4px;
  font-size: 18px;
  color: #2a2727;
}
.qr-code-verify {
  font-weight: 700;
  color: #ffa800;
  margin: 0 4px;
  display: contents;
}
</style>
